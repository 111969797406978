<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.user_id"
        placeholder="用户ID"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.parent_id"
        placeholder="上级ID"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="用户ID" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.user_id }}
        </template>
      </el-table-column>
      <el-table-column label="上级ID" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.parent_id }}
        </template>
      </el-table-column>
      <el-table-column label="团队等级" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_level }}
        </template>
      </el-table-column>
      <el-table-column label="代数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.gene_num }}
        </template>
      </el-table-column>
      <el-table-column label="团队总人数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_person_num }}
        </template>
      </el-table-column>
      <el-table-column label="直推人数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.direct_team_person_num }}
        </template>
      </el-table-column>
      <el-table-column label="大区总人数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.hero_person_num }}
        </template>
      </el-table-column>
      <el-table-column label="小区总人数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.union_person_num }}
        </template>
      </el-table-column>
      <el-table-column label="今日新增人数" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.today_new_person_num }}
        </template>
      </el-table-column>
      <el-table-column label="自己SP" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.basic_exposure }}
        </template>
      </el-table-column>
      <el-table-column label="团队SP" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.team_exposure }}
        </template>
      </el-table-column>
      <el-table-column label="大区SP" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.hero_exposure }}
        </template>
      </el-table-column>
      <el-table-column label="小区SP" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.union_exposure }}
        </template>
      </el-table-column>
      <el-table-column label="提供给上级的SP" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.to_parent_team_exposure }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ scope.row.created_at }}</span>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        user_id: '',
        parent_id: '',
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/team/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
